
.backdrop {
  position: fixed;
  z-index: 5;
  background-color: rgba($color: #000000, $alpha: 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
