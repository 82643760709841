// Custom.scss

// Include any default variable overrides here (though functions won't be available)

$okblue: #043F83;
$okgreen: #8ABA1F;

$primary: $okblue;
$secondary: $okgreen;

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.scss";


// Then add additional custom code here