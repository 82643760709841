.wrapper {
  text-align: center;
  min-height: 100vh;
}

.logo {
  padding: 1em;
  cursor: pointer;
}

.footer{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}