.footer {
  border-top: 1px solid #8ABA1F;
  color: #686868;
  width: 100%;
  padding: 20px;
  text-align: center;
  z-index: 50;
  left: 0;
  bottom: 0;
  background-color: white;
}

.link {
  color: #686868;

  &:hover {
    color: #8aba1f;
  }
}

.separator {
  margin: 0 5px;
}