.table_status {
  text-align: center;
  vertical-align: center;

  &.error {
    background-color: #f8d7da;
  }

  &.success {
    background-color: #d1e7dd;
  }

  &.warning {
    background-color: #fff3cd;
  }

  &.pending {
    background-color: #d4d4d4;
  }
}
