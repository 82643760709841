.pmsIcon {
  width: 25px;
}

.icons {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25px;
  text-align: center;
}

.table_status {
  text-align: center;
  vertical-align: center;

  &.error {
    background-color: #f8d7da;
  }

  &.success {
    background-color: #d1e7dd;
  }

  &.warning {
    background-color: #fff3cd;
  }
}